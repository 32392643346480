
import { defineComponent } from 'vue';
import { TAuth } from "@/types/user";


export default defineComponent({
	name: 'Login',
	data() {
		return {
			error: '',
			data: {
				email: '',
				password: ''
			},
			forgotPassword: false,
			forgotPasswordSent: false,
			msg: '',
			errors: ''
		};
	},
	methods: {
		resetPassword() {
			this.errors = '';
			if (!this.data.email?.trim()) {
				this.errors = 'E-mail address cannot be empty';
			} else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.data.email)) {
				this.errors = 'Please, enter a valid e-mail address';
			}
			if (this.errors){
				return;
			}
			this.errors = '';
			this.$api.users.passwordResetPrepare(this.data.email).
				then((msg) => {
					this.msg = msg;
					this.forgotPasswordSent = true;
				}).catch((msg) => {
					this.errors = msg;
				});
		},

		login() {
			this.errors = '';
			const email = this.data.email.trim();
			const password = this.data.password.trim();

			localStorage.removeItem('token');

			if (email && password) {
				this.$api.users.login<TAuth, undefined>(email, password)
					.catch((error) => this.error = error)
					.then((resp) => {
						if (resp.data?.token) {
							localStorage.setItem('token', resp.data.token);
							this.$bus.emit('logged');
							this.$router.push('/profile/reports.html');
						} else {
							this.error = 'Server cannot generate token';
						}
					});
			}
		}
	}
});
